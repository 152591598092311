.LiveGridPerso {
  &__favoritesWrap {
    max-width: 30rem;
    margin: 6.125rem auto;
    text-align: center;

    @media (--mq-from-desktop) {
      margin-top: 12.25rem;
    }

    @mixin tvDevice {
      max-width: none;
      margin-top: 5rem;

      svg {
        width: 17.5rem;
      }
    }
  }

  &__favoritesIconWrap {
    width: 8.75rem;
    padding: 1.5625rem 2.5rem;
    margin: 0 auto 1.25rem;
    background-color: var(--color_live-tv-favorites-empty-msg-bg);
    border-radius: var(--border-radius-big);
    fill: var(--color_live-tv-favorites-empty-msg-icon);
  }

  &__favoritesHeading {
    margin: 0 0 1rem;
    font-size: 1.375rem;

    @media (--mq-from-tablet) {
      font-size: 1.5rem;
    }

    @mixin tvDevice {
      margin-top: 3.125rem;
      font-size: 2.9375rem;
    }
  }

  &__text {
    [data-theme~='dark'] & {
      color: var(--color_white-50);
    }

    @mixin tvDevice {
      margin: 1.25rem auto 3.125rem;
      font-size: 1.9375rem;
    }
  }

  &__editButtonWrap {
    padding-top: 1.25rem;
    padding-bottom: 3.75rem;
    text-align: center;
  }
}

:global {
  @mixin tvDevice {
    .LiveGridPerso .tv__focus {
      @mixin tv-focus-button;
    }
  }
}
