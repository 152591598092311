.LiveGridTemplate {
  &__grid {
    display: flex;
    flex-wrap: wrap;
  }

  &__gridItem {
    --margin-for-tv: 0.625rem 1.625rem;
    --margin-for-large-desktop: 0.625rem;
    --margin-for-tablet: 0.3125rem;
    --margin-for-small: 0.1875rem;

    @mixin responsiveGrid liveTv;
    @mixin responsiveGrid liveTvV5, true;

    > :first-child {
      @mixin marginGridItems var(--margin-for-large-desktop),
        var(--margin-for-tablet), var(--margin-for-small);
      display: block;
      @mixin tvDevice {
        @mixin marginGridItems var(--margin-for-tv), var(--margin-for-tv),
          var(--margin-for-tv);
      }
    }
  }

  &__prospectLoginMessageWrap {
    padding: 0 0.9375rem;
    margin: 0 auto 1.5625rem;
  }
}

.liveGrid {
  &--focus {
    @mixin tvDevice {
      list-style: none;
    }
  }
}
