@import '../../../assets/base_css/_animations.css';

.LiveGridFeatureCard {
  color: var(--color_white);
  background-image: url('../../../assets/images/multi_live_background.jpg');
  background-size: cover;
  border-radius: var(--border-radius-small);
  fill: var(--color_white);
  @mixin size 100%;

  &--detail {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    z-index: 2;
    width: 25%;
  }

  &__title {
    z-index: 2;
    margin-left: 5%;
  }
}
