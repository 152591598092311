.LiveGridContainer {
  --padding-for-large-desktop: 0 3.125rem;
  --padding-for-desktop: 0 3.4375rem;
  --padding-for-tablet: 0 1.5625rem;
  --padding-for-small: 0 0.8125rem;
  --padding-for-tv: 0 4rem;

  margin-top: 0.625rem;

  @mixin paddingPages var(--padding-for-large-desktop),
    var(--padding-for-desktop), var(--padding-for-tablet),
    var(--padding-for-small);

  @mixin tvDevice {
    @mixin paddingPages var(--padding-for-tv), var(--padding-for-tv),
      var(--padding-for-tv);
  }
}
