.liveGridEditFavoritesButton {
  width: 100%;
  max-width: 20rem;
  font-family: var(--font_hind);
  font-weight: 600;

  @mixin tvDevice {
    max-width: 30rem;
    padding: 0.8125rem 2.5rem;
    font-size: 2.25rem;
  }
}

@mixin tvDevice {
  .liveGrid {
    &--focus {
      list-style: none;
    }
  }
}
